@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;




/* base styles */
* {
    font-family: "Quicksand";

}
.navbar {
    padding: 20px;
    display: flex;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
    border-bottom: 1px solid #f2f2f2;
}
.navbar h1 {
    color: #4a63e3;
}
.navbar .links {
    margin-left: auto;
}
.navbar a {
    margin-left: 16px;
    text-decoration: none;
    padding: 6px;
}
.navbar a:hover {
    color: #355bf1;
}
.content {
    max-width: 100%;
    margin: 40px auto;
    padding: 20px;
}

/* blog preview / list */
.blog-preview{
    padding: 10px 16px;
    border-bottom: 1px solid #f2f2f2;
    margin: 20px 0;
}

.blog-preview:hover{
    box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
}

.blog-preview h2{
    font-size: 20px;
    color: #4a63e3;
    margin-bottom: 8px;
}

.blog-preview a{
    text-decoration: none;
}



.blog-details h2{
    font-size: 20px;
    color: #4a63e3;
    margin-bottom: 8px;
}

.blog-details div{
    margin-bottom: 16px;
}

.blog-details button{
    padding: 8px 16px;
    border: 1px solid #4a63e3;
    background: #4a63e3;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}


.create {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
}
.create label {
    text-align: left;
    display: block;
}
.create h2 {
    font-size: 20px;
    color: #4a63e3;
    margin-bottom: 30px;
}
.create input, .create textarea, .create select {
    width: 100%;
    padding: 6px 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    box-sizing: border-box;
    display: block;
}
.create button {
    color: #fff;
    background: #4a63e3;
    border: 0;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
}

