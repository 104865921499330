/*toolbar with buttons */
#toolbar {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 40px;
    background-color: #E0E0E0;
    border-bottom: 1px solid #C0C0C0;
    z-index: 100;
    overflow: hidden;
}

/*toolbar buttons */
#toolbar button {
    float: left;
    margin: 2px;
    padding: 2px 6px;
    border: 1px solid #C0C0C0;
    border-radius: 4px;
    background-color: #F0F0F0;
    cursor: pointer;
}

/*toolbar buttons hover */

#toolbar button:hover {
    background-color: #E0E0E0;
}

/*toolbar buttons active */

#toolbar button:active {
    background-color: #D0D0D0;
}

/*toolbar buttons disabled */

#toolbar button[disabled] {
    background-color: #F0F0F0;
    border-color: #F0F0F0;
    color: #C0C0C0;
    cursor: default;
}

/*toolbar buttons with icons */

#toolbar button img {
    vertical-align: middle;
}

/*toolbar buttons with text */

#toolbar button span {
    vertical-align: middle;
}


/*floating card*/
.card {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 100;
    display: block;





    /*add space between cards*/
    margin: 10px;

}

/*card title*/

.card .title {
    height: 30px;
    line-height: 30px;
    padding: 0px 6px;
    background-color: #E0E0E0;
    border-bottom: 1px solid #C0C0C0;
    border-radius: 4px 4px 0px 0px;
    font-weight: bold;
    font-size: 14px;
    color: #000000;
}

/*card content*/

.card .content {
    padding: 6px;
    font-size: 12px;
    color: #000000;
}

/*card buttons*/

.card .buttons {
    bottom: 6px;
    right: 6px;



}

/*card buttons button*/

.card .buttons button {
    margin-left: 6px;
    padding: 2px 6px;
    border: 1px solid #C0C0C0;
    border-radius: 4px;
    color: #F0F0F0;
    /*make buttons bigger*/
    font-size: 14px;
    font-weight: bold;
    background-color: #E0E0E0;

    background-color: #3554f1;
    cursor: pointer;
}

